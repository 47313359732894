@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base

{
    @font-face {
        font-family: 'Game Shark';
        src: local('Game Shark'), url('/src/Forked/fonts/game-shark.regular.otf');
    }
    @font-face {
        font-family: 'Kalam Light';
        src: local('Kalam Light'),url('/src/Forked/fonts/kalam.light.ttf')
    }
    @font-face {
        font-family:'Betatron Regular' ;
        src: local('Betatron Regular') url('/src/Forked/fonts/Betatron-Regular.otf');
    }
    @font-face {
        font-family: 'Genesys';
        src:local('Genesys') url('/src/Forked/fonts/Genesys.ttf');
    }
    @font-face {
        font-family: 'Poppins' ;
        src: local('Poppins') url('/src/Forked/fonts/Poppins-Regular.ttf');
    }
    @font-face {
        font-family: 'Future Now';
        src: local('Future Now') url('/src/Forked/fonts/FutureNow.ttf');
    }
    

}


